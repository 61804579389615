import React from 'react'
import image from "../Images/SierraProject/Sierra.png"
import "./Sierra.css"

const Sierra = () => {
    return (
            <div className = "sierra-container">
                <img src = { image } className = "sierra-image"></img>
            </div>
    );
}

export default Sierra;